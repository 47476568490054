const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://ve5n8fpm9e.execute-api.eu-west-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://ex9ysl2oa6.execute-api.eu-west-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.9.3',
    HOSTNAME: 'https://teams-service.europe.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.europe.forwoodsafety.com',
    WEBSOCKET: 'wss://a1l7hepeqj.execute-api.eu-west-1.amazonaws.com/prod'
  },
};

export default config;
